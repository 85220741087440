import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/global.css";

import InstagramOAuth from "./pages/instagram/InstagramOAuth";
import InstagramCallback from "./pages/instagram/InstagramCallback";
import GenericError from "./pages/error/GenericError";
import TwitterOAuth from "./pages/twitter/TwitterOAuth";
import TwitterCallback from "./pages/twitter/TwitterCallback";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/instagram/auth" element={<InstagramOAuth />} />
        <Route path="/instagram/callback" element={<InstagramCallback />} />
        <Route path="/twitter/auth" element={<TwitterOAuth />} />
        <Route path="/twitter/callback" element={<TwitterCallback />} />
        <Route path="/app_error" element={<GenericError />} />
      </Routes>
    </Router>
  );
}

export default App;
