/* eslint-disable import/no-anonymous-default-export */
const AUTH_NAME = {
  INSTAGRAM: "instagram",
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
};

const AUTH_STATUS = {
  SUCCESS: "success",
  FAILURE: "failure",
};

const prefix = "ech://sis";

const GENERIC_CLOSE = `${prefix}_close`;

export default {
  IG_SUCCESS: `${prefix}_${AUTH_NAME.INSTAGRAM}_${AUTH_STATUS.SUCCESS}`,
  IG_FAILURE: `${prefix}_${AUTH_NAME.INSTAGRAM}_${AUTH_STATUS.FAILURE}`,
  TW_SUCCESS: `${prefix}_${AUTH_NAME.TWITTER}_${AUTH_STATUS.SUCCESS}`,
  TW_FAILURE: `${prefix}_${AUTH_NAME.TWITTER}_${AUTH_STATUS.FAILURE}`,
  LI_SUCCESS: `${prefix}_${AUTH_NAME.LINKEDIN}_${AUTH_STATUS.SUCCESS}`,
  LI_FAILURE: `${prefix}_${AUTH_NAME.LINKEDIN}_${AUTH_STATUS.FAILURE}`,
  GENERIC_CLOSE,
};
