import { PropagateLoader } from "react-spinners";

const FullPagePropagateLoader = () => {
  return (
    <div className="global-container">
      <PropagateLoader color="#9196FF" />
    </div>
  );
};

export default FullPagePropagateLoader;
