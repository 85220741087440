import { useEffect } from "react";
import instagramApi from "../../apis/instagram.api";
import appDeeplinks from "../../constants/appDeeplinks";
import FullPagePropagateLoader from "../../components/FullPagePropagateLoader";

const InstagramCallback = () => {
  const triggerDeeplink = (dl) => {
    window.location.href = dl;
  };

  const init = async () => {
    try {
      await instagramApi.captureAuthCode();
      triggerDeeplink(appDeeplinks.IG_SUCCESS);
    } catch (error) {
      console.error("ERROR: while saving auth code. ", error.message);
      triggerDeeplink(appDeeplinks.IG_FAILURE);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullPagePropagateLoader />;
};

export default InstagramCallback;
