import { useEffect, useState } from "react";
import appDeeplinks from "../../constants/appDeeplinks";

const GenericError = () => {
  const [timer, setTimer] = useState(2);

  const handleError = () => {
    // close window after 2 seconds
    setTimeout(() => {
      window.location.href = appDeeplinks.GENERIC_CLOSE;
    }, 2000);
    // count down timer
    const interval = setInterval(() => {
      setTimer((prev) => Math.max(0, prev - 1));
    }, 1000);
    // clear interval on unmount
    return () => clearInterval(interval);
  };

  useEffect(() => {
    handleError();
  }, []);

  return (
    <div className="global-container">
      <h2 className="primary-text">
        Something went wrong. Please try again later.
      </h2>
      <p className="secondary-text">
        This window will close in {timer} seconds.
      </p>
    </div>
  );
};

export default GenericError;
