import { RingLoader } from "react-spinners";

const FullPageRingLoader = () => {
  return (
    <div className="global-container">
      <RingLoader color="#9196FF" />
    </div>
  );
};

export default FullPageRingLoader;
