import { useEffect } from "react";
import instagramApi from "../../apis/instagram.api";
import { useNavigate } from "react-router-dom";
import FullPageRingLoader from "../../components/FullPageRingLoader";

const InstagramOAuth = () => {
  const navigate = useNavigate();

  const init = async () => {
    try {
      const authUrl = await instagramApi.getAuthUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error(
        "[INSTAGRAM ERROR]: while fetching auth URL. ",
        error.message
      );
      navigate("/app_error");
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullPageRingLoader />;
};

export default InstagramOAuth;
