/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { InstagramRegistry, constants } from "./registry";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAuthUrl = async () => {
  const token = localStorage.getItem(constants.AUTH_TOKEN_KEY);
  if (!Boolean(token)) {
    throw new Error("Unauthorized access. Please log in first.");
  }
  const { data } = await axios.get(
    API_BASE_URL + InstagramRegistry.GET_AUTH_URL
  );
  const { authUrl } = data;
  const url = new URL(authUrl);
  url.searchParams.append("state", token);
  return url.href;
};

const captureAuthCode = async () => {
  const url = new URL(window.location.href);
  const code = url.searchParams.get("code");
  const state = url.searchParams.get("state");
  if (!Boolean(code) || !Boolean(state)) {
    throw new Error(
      "Invalid authorisation response. Code and/or token not found"
    );
  }
  await axios.post(API_BASE_URL + InstagramRegistry.POST_CAPTURE_CODE, null, {
    params: {
      code,
    },
    headers: {
      Authorization: state,
    },
  });
};

export default {
  getAuthUrl,
  captureAuthCode,
};
