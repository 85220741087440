import { useEffect } from "react";
import twitterApi from "../../apis/twitter.api";
import appDeeplinks from "../../constants/appDeeplinks";
import FullPagePropagateLoader from "../../components/FullPagePropagateLoader";

const TwitterCallback = () => {
  const triggerDeeplink = (dl) => {
    window.location.href = dl;
  };

  const init = async () => {
    try {
      await twitterApi.captureAuthCode();
      triggerDeeplink(appDeeplinks.TW_SUCCESS);
    } catch (error) {
      console.error("ERROR: while saving auth code. ", error.message);
      triggerDeeplink(appDeeplinks.TW_FAILURE);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullPagePropagateLoader />;
};

export default TwitterCallback;
