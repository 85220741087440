export const InstagramRegistry = {
  GET_AUTH_URL: "/instagram/auth",
  POST_CAPTURE_CODE: "/instagram/auth",
};

export const TwitterRegistry = {
  GET_AUTH_URL: "/twitter/auth",
  POST_CAPTURE_CODE: "/twitter/auth",
};

export const constants = {
  AUTH_TOKEN_KEY: "token",
};
